import React, { useState } from 'react';
import PageHeader from '../Component/PageHeader';
import {
	Card,
	CardContent,
	TextField,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Stack,
	Alert
} from '@mui/material';
import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const domains = [
	{ value: "Select an Account", label: "Select an Account" },
	{ value: "webdesignexpressions.net", label: "webdesignexpressions.net" },
	{ value: "bostonairportlimos.com", label: "bostonairportlimos.com" },
	{ value: "driverlicenseprep.com", label: "driverlicenseprep.com" },
	{ value: "chartergoinfissioncabo.com", label: "chartergoinfissioncabo.com" }
];

const MetaAdd = () => {
	const [selectedDomain, setSelectedDomain] = useState('');
	const [pagePath, setPagePath] = useState('');
	const [formData, setFormData] = useState({
		pageTitle: '',
		title: '',
		description: '',
		keywords: '',
		ogtitle: '',
		ogtype: '',
		ogdescription: ''
	});
	const [status, setStatus] = useState({ type: '', message: '' });
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({
			...prev,
			[name]: value
		}));
	};

	const checkForDuplicates = async () => {
		if (!selectedDomain || selectedDomain === 'Select an Account') return false;

		try {
			const docRef = doc(db, 'Pages', selectedDomain);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const data = docSnap.data();
				const pages = data.pages || {};

				// Check if page path already exists
				if (pages[pagePath] && !window.confirm('This page already exists. Do you want to update it?')) {
					return true;
				}

				// Check for duplicates in metadata
				for (const [existingPath, pageData] of Object.entries(pages)) {
					if (existingPath === pagePath) continue;

					for (const [field, value] of Object.entries(formData)) {
						if (value && pageData[field] === value) {
							if (!window.confirm(`'${value}' is already used in ${field} for page ${existingPath}. Continue anyway?`)) {
								return true;
							}
						}
					}
				}
			}
			return false;
		} catch (error) {
			setStatus({ type: 'error', message: `Error checking duplicates: ${error.message}` });
			return true;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setStatus({ type: '', message: '' });

		try {
			// Validations
			if (!selectedDomain || selectedDomain === 'Select an Account') {
				throw new Error('Please select a domain');
			}

			if (!pagePath) {
				throw new Error('Please enter a page path');
			}



			// Required fields validation
			const requiredFields = ['pageTitle', 'title', 'description'];
			for (const field of requiredFields) {
				if (!formData[field]) {
					throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
				}
			}

			// Check for duplicates
			const hasDuplicates = await checkForDuplicates();
			if (hasDuplicates) {
				setLoading(false);
				return;
			}

			// Save to Firestore
			const docRef = doc(db, 'Pages', selectedDomain);
			await setDoc(docRef, {
				pages: {
					[pagePath]: formData
				}
			}, { merge: true });

			// Clear form
			setFormData({
				pageTitle: '',
				title: '',
				description: '',
				keywords: '',
				ogtitle: '',
				ogtype: '',
				ogdescription: ''
			});
			setPagePath('');
			setStatus({ type: 'success', message: 'Metadata saved successfully!' });
		} catch (error) {
			setStatus({ type: 'error', message: error.message });
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<PageHeader pageTitle="Add Meta Information" />
			<div className='mx-16'>
				<Card sx={{
					boxShadow: 3,
					borderRadius: 2,
					padding: 0,
					backgroundColor: 'transparent',
					backdropFilter: 'blur(10px)',
					border: '1px solid rgba(0, 0, 0, 0.2)',
				}}>
					<CardContent>
						<form onSubmit={handleSubmit}>
							<Stack spacing={3}>
								<FormControl fullWidth>
									<InputLabel id="domain-select-label">Select Domain</InputLabel>
									<Select
										labelId="domain-select-label"
										value={selectedDomain}
										label="Select Domain"
										onChange={(e) => setSelectedDomain(e.target.value)}
										required
									>
										{domains.map((domain) => (
											<MenuItem key={domain.value} value={domain.value}>
												{domain.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<TextField
									label="Page Path"
									value={pagePath}
									onChange={(e) => setPagePath(e.target.value)}
									placeholder="/about-us"
									helperText="Must start with /"
									required
									fullWidth
								/>

								<TextField
									label="Page Title"
									name="pageTitle"
									value={formData.pageTitle}
									onChange={handleInputChange}
									required
									fullWidth
								/>

								<TextField
									label="Meta Title"
									name="title"
									value={formData.title}
									onChange={handleInputChange}
									required
									inputProps={{ maxLength: 60 }}
									helperText="Length 60 Characters"
									fullWidth
								/>

								<TextField
									label="Meta Description"
									name="description"
									value={formData.description}
									onChange={handleInputChange}
									required
									inputProps={{ maxLength: 150 }}
									helperText="Length 150 Characters"
									multiline
									rows={3}
									fullWidth
								/>

								<TextField
									label="Keywords"
									name="keywords"
									value={formData.keywords}
									onChange={handleInputChange}
									helperText="Separate keywords with commas"
									fullWidth
								/>

								<TextField
									label="OG Title"
									name="ogtitle"
									value={formData.ogtitle}
									onChange={handleInputChange}
									inputProps={{ maxLength: 60 }}
									helperText="Length 60 Characters"
									fullWidth
								/>

								<TextField
									label="OG Type"
									name="ogtype"
									value={formData.ogtype}
									onChange={handleInputChange}
									fullWidth
								/>

								<TextField
									label="OG Description"
									name="ogdescription"
									value={formData.ogdescription}
									onChange={handleInputChange}
									inputProps={{ maxLength: 200 }}
									helperText="Length 200 Characters"
									multiline
									rows={3}
									fullWidth
								/>

								<Button
									type="submit"
									variant="contained"
									color="primary"
									disabled={loading}
									fullWidth
								>
									{loading ? 'Saving...' : 'Save Metadata'}
								</Button>

								{status.message && (
									<Alert severity={status.type === 'error' ? 'error' : 'success'}>
										{status.message}
									</Alert>
								)}
							</Stack>
						</form>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default MetaAdd;