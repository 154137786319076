import React, { useRef, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { toPng } from 'html-to-image';

const QR = () => {
    const qrRef = useRef(null);
    const [companyName, setCompanyName] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [qrValue, setQrValue] = useState('');
    const [qrColor, setQrColor] = useState('#000000');
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogoFile(file);
            const url = URL.createObjectURL(file);
            setLogoUrl(url);
        }
    };

    const handleGenerateQR = () => {
        setQrValue(`Company name: ${companyName}\nURL: ${websiteUrl}`);
    };

    const downloadQR = () => {
        if (qrRef.current === null) return;

        const scale = 4;
        const options = {
            quality: 1,
            pixelRatio: scale,
            width: 512 * scale,
            height: 512 * scale,
            backgroundColor: null
        };

        toPng(qrRef.current, options)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `${companyName.replace(/\s+/g, '-')}-qr-code.png`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => console.error(err));
    };

    return (
        <div className="p-6 max-w-2xl mx-auto">
            <div className="space-y-4">
                <div className="grid grid-cols-1 gap-4">
                    <input
                        type="text"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="border rounded p-2"
                    />
                    <input
                        type="text"
                        placeholder="Website URL"
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                        className="border rounded p-2"
                    />
                    <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            placeholder="#000000"
                            value={qrColor}
                            onChange={(e) => setQrColor(e.target.value)}
                            className="border rounded p-2 w-32"
                        />
                        <input
                            type="color"
                            value={qrColor}
                            onChange={(e) => setQrColor(e.target.value)}
                            className="h-8 w-16"
                        />
                    </div>
                    <div>
                        <label className="block text-sm mb-2">Upload Logo (PNG or SVG recommended)</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleLogoChange}
                            className="w-full"
                        />
                    </div>
                    <button
                        onClick={handleGenerateQR}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                    >
                        Create QR Code
                    </button>
                </div>

                {qrValue && (
                    <div className="mt-6">
                        <div
                            ref={qrRef}
                            className="relative inline-block"
                            style={{
                                background: 'transparent',
                                padding: '20px'
                            }}
                        >


                            <QRCodeCanvas
                                value={qrValue}
                                size={512}
                                bgColor="transparent"
                                fgColor={qrColor}
                                level="H"
                                includeMargin={false}
                                imageSettings={logoUrl ? {
                                    src: logoUrl,
                                    height: 64,
                                    width: 64,
                                    excavate: true,
                                } : undefined}
                            />


                        </div>
                        <button
                            onClick={downloadQR}
                            className="mt-8 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors w-full"
                        >
                            Download High-Res QR Code
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QR;