import React from 'react';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from 'react-helmet-async';

const today = new Date();
const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",   // Full weekday name (e.g., "Wednesday")
    day: "numeric",    // Day of the month (e.g., "4")
    month: "long",     // Full month name (e.g., "December")
    year: "numeric",   // Full year (e.g., "2024")
}).format(today);

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const TermsofUse = () => {
    return (
        <div>


            <Helmet prioritizeSeoTags>
                <title>Terms of Use | Web Design Expressions</title>
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Read Web Design Expressions' Terms of Use. By using our site or services, you agree to terms covering payments, IP, user responsibilities & more"
                />
                <meta
                    data-react-helmet="true"
                    name="keywords"
                    content="Web Design Expressions Terms of Use"
                />
                <meta data-react-helmet="true" property="og:title" content="Terms of Use | Web Design Expressions" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/TermsofUse" />
                <meta
                    data-react-helmet="true"
                    property="og:image"
                    content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGCustomWebDesign.jpg?alt=media&token=ea17b35d-cbad-4a64-82aa-436f90c70a15"
                />
                <meta
                    data-react-helmet="true"
                    property="og:description"
                    content="Read Web Design Expressions' Terms of Use. By using our site or services, you agree to terms covering payments, IP, user responsibilities & more"
                />
            </Helmet>
            <PageHeader pageTitle="Terms of Use" />
            <section className='mx-16'>
                <div>
                    <p className='mb-4'>
                        <span>Effective Date:</span>{" "}
                        <span>{formattedDate}</span>{" "}
                    </p>
                    <p>
                        <span>Welcome to </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>! By accessing or using our website, services, or products, you agree to comply with and be bound by these Terms of Use (the "Terms"). If you do not agree to these Terms, you must not use our website or services. Please read these Terms carefully.</span>{" "}
                    </p>
                </div>

                <article>
                    <h2 className='py-4 font-bold'>1. Acceptance of Terms</h2>
                    <p>
                        <span>By accessing or using the </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>website or any related services, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree to these Terms, do not use our website or services.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>2. Services Provided</h2>
                    <p>
                        <span><CompanyName /></span>{" "}
                        <span>provides web design and related services (collectively, the "Services"). These Terms apply to all users of our Services, including browsers, clients, vendors, merchants, and/or contributors of content.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>3. Payment Terms</h2>
                    <p className='pb-4'>An initial payment of 50% of the total project or service cost is required before any work begins. This upfront payment is non-refundable and secures your place in our production schedule. The remaining balance must be paid according to the timeline communicated at the start of the project.</p>
                    <p>Failure to make payments on time may result in the suspension of work, services, or the temporary removal of your website or associated deliverables. Ownership of all project deliverables, including website files, designs, and other materials, will only be transferred to the client upon receipt of full payment.</p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>4. Intellectual Property Rights</h2>
                    <p>
                        <span>All content on the </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>website, including but not limited to text, graphics, logos, images, designs, videos, software, and source code (the "Content"), is the exclusive property of</span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>or its licensors and is protected by copyright, trademark, and other intellectual property laws.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>Prohibited Uses of Content</h2>
                    <p>You may not:</p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Copy, reproduce, distribute, publish, display, modify, or create derivative works of our Content without prior written consent from Web Design Expressions." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Use any images, logos, or design elements from our website for commercial or non-commercial purposes." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Frame or embed any part of the website or Content in another website without explicit written permission." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Use automated tools (e.g., bots, scrapers) to access, download, or copy Content." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>5. User Responsibilities</h2>
                    <p>
                        <span>You agree not to use the </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>website or Services for any unlawful purpose or in a way that violates these Terms. Specifically, you agree not to:</span>{" "}
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Engage in unauthorized access, tampering, or hacking of our website or servers." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Upload or distribute malicious code or other harmful materials." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Misrepresent yourself or provide false information." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Use the website for any activity that infringes on the intellectual property rights of Web Design Expressions or any third party." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>6. Account Registration</h2>
                    <p>If you register for an account, you agree to:</p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Provide accurate, current, and complete information." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Maintain the confidentiality of your account credentials." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Notify Web Design Expressions immediately of any unauthorized use of your account." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>7. Third-Party Links</h2>
                    <p>
                        <span>Our website may contain links to third-party websites. These links are provided for convenience and do not signify our endorsement of the content on those websites. </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>is not responsible for any third-party content or practices.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>8. Prohibition Against Copying Designs</h2>
                    <p className='pb-4'>
                        <span>You are expressly prohibited from copying, imitating, or replicating any website designs, layouts, or features created by </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>, whether visible on our website or provided to clients as part of our services.</span>{" "}
                        <span>If you are found to be using designs created by </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>without proper authorization, we reserve the right to pursue legal action, including claims for damages, lost profits, and injunctions to prevent further infringement.</span>{" "}

                    </p>

                </article>

                <article>
                    <h2 className='py-4 font-bold'>9. Disclaimer of Warranties</h2>
                    <p>Our website and Services are provided "as is" and "as available," without any warranties of any kind, either express or implied, including but not limited to:</p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Warranties of merchantability or fitness for a particular purpose." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Warranties of non-infringement." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Warranties that the website or services will be error-free or uninterrupted." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>10. Limitation of Liability</h2>
                    <p>
                        <span>To the fullest extent permitted by law, </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>shall not be liable for any:</span>{" "}
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Direct, indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use our website or services." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Loss of data, revenue, or profits, even if advised of the possibility of such damages." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>11. Indemnification</h2>
                    <p>
                        <span>You agree to indemnify, defend, and hold harmless </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>and its affiliates, employees, and contractors from any claims, damages, or expenses (including attorney fees) arising from:</span>{" "}
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Your use of the website or Services." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Your violation of these Terms." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Your infringement of any intellectual property or other rights of Web Design Expressions or third parties." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>12. Termination</h2>
                    <p>We reserve the right to terminate or suspend your access to our website or Services, without notice or liability, for any reason, including violation of these Terms.</p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>13. Governing Law and Dispute Resolution</h2>
                    <p>These Terms are governed by and construed in accordance with the laws of the United States. Any disputes arising out of or relating to these Terms shall be resolved in the federal or state courts located within the United States, and you consent to the jurisdiction of such courts.</p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>14. Changes to Terms</h2>
                    <p>
                        <span><CompanyName /> </span>{" "}
                        <span>reserves the right to update or modify these Terms at any time without prior notice. Your continued use of our website or services constitutes your acceptance of the revised Terms.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='py-4 font-bold'>15. Contact Information</h2>
                    <p>If you have any questions about these Terms, please contact us at:</p>
                    <div className='lg:w-1/4 mx-auto'>
                        <div className='my-8'>
                            <ThemeProvider theme={theme}>
                                <Button className='' fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size='large' aria-label='Contact Web Design Expressions Designs, the Web design and SEO company' title="Contact Web Design Expressions, the Web design and SEO company" href='/Contact'>
                                    <EmailIcon />&nbsp;&nbsp;<b>Contact us</b>
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                    <p>
                        <span className='font-bold'>Note:</span>{" "}
                        <span>By using this website, you agree to abide by these Terms of Use. Failure to comply may result in legal action.</span>{" "}
                    </p>
                </article>
            </section>
        </div>
    )
}

export default TermsofUse