import React, { useState } from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/system';
import faqData from '../Component/faqData.json'

import Whatdoesawebdesigncompanydo from './Whatdoesawebdesigncompanydo';
import Howmuchdoesitcosttobuildawebsite from './Howmuchdoesitcosttobuildawebsite';

const answerComponents = {
    "<Whatdoesawebdesigncompanydo/>": <Whatdoesawebdesigncompanydo />,
    "<Howmuchdoesitcosttobuildawebsite/>": <Howmuchdoesitcosttobuildawebsite />
};

const FAQSection = ({ page }) => {

    // Custom styles
    const TransparentAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: 'transparent',
        color: 'inherit',
    }));

    const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
        backgroundColor: 'transparent',
        color: 'inherit',
    }));

    const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
        backgroundColor: 'transparent',
        color: 'inherit',
    }));

    const [expanded, setExpanded] = useState(false);

    // Filter FAQs by the specified page
    const filteredFAQs = page
        ? faqData.customwebdesign.filter((faq) => faq.page === page)
        : faqData.customwebdesign;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <section>
            <dl>
                {filteredFAQs.map((faq, index) => (
                    <TransparentAccordion
                        key={faq.id}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        className="my-5"
                    >
                        <TransparentAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <dt className="font-bold">
                                <div style={{ color: 'inherit' }}>
                                    <span>{faq.question}</span>{" "}
                                </div>
                            </dt>
                        </TransparentAccordionSummary>
                        <TransparentAccordionDetails>
                            <dd>
                                <span>
                                    {/* Render the component based on the answer key */}
                                    {answerComponents[faq.answer] || (
                                        <p>Answer not found</p>
                                    )}
                                </span>{" "}
                            </dd>
                        </TransparentAccordionDetails>
                    </TransparentAccordion>
                ))}
            </dl>
        </section>
    )
}

export default FAQSection