import projects from './Projects.json';
import { Helmet } from 'react-helmet-async';
import { Link } from '@mui/material';
import { useSprings, animated } from '@react-spring/web';
import { useState, useEffect } from 'react';
import PageHeader from '../Component/PageHeader';
import CompanyName from '../Component/CompanyName';

const Projects = ({ src, alt }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Animation for project cards with hover effect
    const [springs, api] = useSprings(projects.length, index => ({
        transform: 'scale(1)',
        config: { tension: 200, friction: 15 },
    }));

    const handleMouseEnter = (index) => {
        api.start(i =>
            i === index
                ? {
                    transform: 'scale(0.95)', // Slightly shrink the image
                }
                : {}
        );
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        api.start({
            transform: 'scale(1)', // Reset back to original size
        });
        setHoveredIndex(null);
    };

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setDimensions({ width: img.width, height: img.height });
        };
    }, [src]);

    return (
        <animated.div>
            <div><PageHeader pageTitle="Our Custom Web Design Portfolio" /></div>
            <Helmet prioritizeSeoTags>
                <title>Previous and Current Custom Web Design Projects</title>
                <meta data-react-helmet="true" name="description" content="We specializes in affordable & professional custom websites. We deliver business website design services that empower small businesses across the USA." />
                <meta data-react-helmet="true" name="keyword" content="business website design services, custom web design services, professional web design agency, affordable web design services, USA web design company" />
                <meta data-react-helmet="true" property="og:title" content="Previous and Current Custom Web Design Projects" />
                <meta data-react-helmet="true" property="og:type" content="profile" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGProjects.jpg?alt=media&token=01014adf-a0dc-4404-8a6e-f0042066b22b" />
                <meta data-react-helmet="true" property="og:description" content="Web Design Expressions specializes in affordable & professional custom websites. We deliver business website design services that empower small businesses across the USA." />
            </Helmet>

            {/* Flexbox container for projects */}
            <div className="mx-16">

                <p>
                    <span data-translate>At </span>{' '}
                    <CompanyName />{' '}
                    <span data-translate>
                        we specialize in creating tailored, professional websites for businesses of all sizes. Our portfolio showcases our expertise in providing affordable web design services that align with your business goals. Whether you're looking for a custom web design agency or seeking reliable business website design services, our work speaks for itself. From concept to execution, we focus on delivering high-quality, results-driven websites.
                    </span>
                </p>

                <div className="flex flex-wrap justify-center gap-4 pt-5 mx-auto">
                    {/* Sort projects by year in descending order */}
                    {springs.map((style, index) => {
                        const sortedProjects = [...projects].sort((a, b) => b.ProjectYear - a.ProjectYear); // Sort by year descending
                        const project = sortedProjects[index];

                        return (
                            <animated.div
                                key={project.ID}
                                style={style} // Apply the spring styles
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                className="relative overflow-hidden"
                            >
                                {/* Hidden text, will appear on hover for hovered image only */}
                                {hoveredIndex === index && (
                                    <animated.div
                                        style={{
                                            opacity: 1,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 2,
                                            textAlign: 'center',
                                            color: '#c7d5e0',
                                            backgroundColor: 'rgba(23, 26, 33, 0.7)',
                                            padding: '50px',
                                            pointerEvents: 'none',
                                            borderRadius: '25px'
                                        }}
                                    >
                                        <Link
                                            href={project.ProjectURL}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <div
                                                className='lg:text-6xl font-bold'
                                                style={{
                                                    fontSize: '3rem',
                                                    marginBottom: '1rem',
                                                }}
                                            >
                                                {project.ProjectName || 'Untitled Project'}
                                            </div>
                                        </Link>

                                        <div
                                            style={{
                                                fontSize: '1.85rem',
                                                position: 'relative',
                                                right: '0',
                                                textAlign: 'right'
                                            }}
                                        >
                                            {project.ProjectYear}
                                        </div>
                                    </animated.div>
                                )}

                                <div className="relative">
                                    <div className="mb-5">
                                        <Link
                                            href={project.ProjectURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <animated.img
                                                src={project.ProjectImage}
                                                alt={`${project.ProjectName} - business website design services in USA, ${project.ProjectYear}`}
                                                title={`${project.ProjectName} - Professional Web Design Project by Web Design Expressions | Business Website Design Services ${project.ProjectYear}`}
                                                width="960"
                                                height="641"

                                                className="shadow-md mx-auto w-full h-auto"
                                                style={{
                                                    ...style,
                                                    backgroundColor: 'transparent',
                                                    objectFit: 'cover',


                                                }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </animated.div>
                        );
                    })}
                </div>
            </div>
        </animated.div>
    );
};

export default Projects;
