import React from 'react';
import sitemapData from './Component/links.json';
import PageHeader from './Component/PageHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Helmet } from 'react-helmet-async';

const Sitemap = () => {
    const generateSitemap = (categoryData) => {
        return categoryData.map((item, index) => (
            <ListItem key={index} disablePadding>
                <ListItemButton component="a" href={item.url} title={item.LinkTitle || item.title}>
                    <ListItemText primary={<>
                        <span>{item.title}</span>{" "}
                    </>} />
                    <ListItemIcon>
                        <ArrowRightIcon />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        ));
    };

    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>Sitemap | Web Design Expressions</title>
                <meta
                    data-react-helmet="true"
                    name="keywords"
                    content="Sitemap, Web Design Services."
                />
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Explore the sitemap of Web Design Expressions to navigate through our professional web design services"
                />
                <meta
                    data-react-helmet="true"
                    property="og:title"
                    content="Sitemap | Web Design Expressions"
                />
                <meta property="og:type" content="website" />
                <meta
                    data-react-helmet="true"
                    property="og:url"
                    content="https://webdesignexpressions.net/Sitemap"
                />
                <meta
                    data-react-helmet="true"
                    property="og:image"
                    content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGCustomWebDesign.jpg?alt=media&token=ea17b35d-cbad-4a64-82aa-436f90c70a15"
                />
                <meta
                    data-react-helmet="true"
                    property="og:description"
                    content="Find all pages on Web Design Expressions using our sitemap. Discover our wide range of web design services."
                />
            </Helmet>
            <PageHeader pageTitle="Sitemap" />
            <article>

                <section className="grid lg:grid-cols-3 md:grid-cols-3 gap-8 mx-16">
                    {Object.keys(sitemapData).map((category, index) => (
                        <section key={index}>
                            <List aria-label={category}>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={category}
                                        primaryTypographyProps={{ fontSize: 'large', fontWeight: 'bold' }}
                                    />
                                </ListItem>
                                <div className='py-2 w-1/2'><Divider /></div>

                                {generateSitemap(sitemapData[category])}

                            </List>
                        </section>
                    ))}
                </section>
            </article>
        </div>
    );
};

export default Sitemap;
