import React, { useState } from 'react';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import EcommerceImage from './Images/E-commerceSolutions.webp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BuildIcon from '@mui/icons-material/Build';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ECommerceBenefits from './Component/ECommerceBenefits';
import WhyChoose from './Component/WhyChoose';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';


const Ecommerce = () => {

    const ChooseEcommerce = {
        service: "E-commerce Solutions",
        companyName: "YourCompany",
        introText: "Want to take your online store to the next level? Our custom e-commerce solutions empower you to provide seamless shopping experiences while boosting your revenue. From storefronts to payment gateways, we’ve got you covered.",
        benefits: [
            {
                title: "Custom E-Commerce Design",
                description: "We build visually stunning, user-friendly online stores that reflect your brand identity and keep customers engaged."
            },
            {
                title: "Secure Payment Integration",
                description: "Offer your customers peace of mind with secure and efficient payment processing solutions that work flawlessly."
            },
            {
                title: "Inventory Management Made Easy",
                description: "Our e-commerce platforms include intuitive tools to manage your products, stock levels, and orders efficiently."
            },
            {
                title: "Optimized for Sales",
                description: "We design your e-commerce site with conversion-focused strategies to turn visitors into loyal customers."
            },
            {
                title: "Scalable for Growth",
                description: "Our e-commerce solutions grow with your business, supporting increased traffic and expanding product ranges effortlessly."
            }
        ],
        finalHeader: "Transform Your Online Store Today",
        finalText: "Let us build an e-commerce platform that delivers results. Contact us to explore how we can help you create a thriving online store."
    };

    const ECommerceProcess = [
        {
            id: 1,
            Process: 'Consultation and Planning',
            Description: 'We start by understanding your business goals, target audience, and unique requirements. Through a collaborative approach, we develop a comprehensive strategy to build an online store tailored to your vision.'
        },
        {
            id: 2,
            Process: 'Design and Development',
            Description: 'Our team of expert designers and developers brings your store to life. We focus on creating a visually appealing, user-friendly interface while ensuring the backend is robust and efficient.'
        },
        {
            id: 3,
            Process: 'Product Setup and Optimization',
            Description: 'We help you showcase your products effectively with high-quality images, detailed descriptions, and optimized categories. This ensures that your customers can easily find what they’re looking for and make informed purchasing decisions.'
        },
        {
            id: 4,
            Process: 'Testing and Launch',
            Description: 'Before going live, we rigorously test every aspect of your e-commerce site, from functionality to security. Once everything is flawless, we launch your store and ensure it’s ready to handle your customers seamlessly.'
        },
        {
            id: 5,
            Process: 'Ongoing Support and Maintenance',
            Description: 'An e-commerce site requires continuous updates and maintenance to keep up with market trends and customer demands. Our team provides ongoing support to ensure your store remains up-to-date and performs optimally.'
        },

    ]

    // Custom styled components
    const TransparentAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
        color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
    }));

    const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
        color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
    }));

    const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
        color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
    }));

    const CustomExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
        color: theme.palette.mode === 'dark' ? '#F13030' : '#F13030',
    }));


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>

            <Helmet prioritizeSeoTags>
                <title>Hire a Custom E-commerce Web Design Company</title>
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Looking for a reliable e-commerce web design company? We provide custom e-commerce solutions designed to meet your business needs."
                />
                <meta
                    data-react-helmet="true"
                    name="keywords"
                    content="ecommerce web design company, custom ecommerce solutions, hire ecommerce web design company"
                />
                <meta
                    data-react-helmet="true"
                    property="og:title"
                    content="Hire a Custom E-commerce Web Design Company"
                />
                <meta
                    data-react-helmet="true"
                    property="og:type"
                    content="business"
                />
                <meta
                    data-react-helmet="true"
                    property="og:url"
                    content="https://webdesignexpressions.net/Ecommerce"
                />
                <meta
                    data-react-helmet="true"
                    property="og:image"
                    content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGCustomWebDesign.jpg?alt=media&token=ea17b35d-cbad-4a64-82aa-436f90c70a15"
                />
                <meta
                    data-react-helmet="true"
                    property="og:description"
                    content="Looking for a reliable e-commerce web design company? We provide custom e-commerce solutions designed to meet your business needs."
                />
            </Helmet>
            <PageHeader pageTitle="E-Commerce Solutions to Grow Your Business" />
            <div className=''>
                <div>

                    <section className='mx-16'>
                        <article>
                            <div className='text-xl font-bold'>
                                <h2>E-Commerce Solutions That Drive Your Online Sales</h2>
                            </div>
                            <div className='py-4'>
                                <span>In the digital age, e-commerce is no longer just an option—it's a necessity. At </span>{" "}
                                <span><CompanyName /></span>{" "}
                                <span>, we specialize in providing custom e-commerce solutions that empower businesses to thrive in the competitive online marketplace. Whether you're starting a new online store or looking to improve an existing one, we deliver tailored strategies and cutting-edge technologies to help you increase sales, improve user experience, and grow your business.</span>{" "}
                            </div>
                        </article>
                    </section>


                    <section className='mx-16'>
                        <article>
                            <div className='text-xl font-bold'>
                                <h2>Why Invest in E-Commerce Solutions?</h2>
                            </div>
                            <div className='pt-4'>
                                <span>
                                    The way people shop has changed. Consumers now expect a seamless and convenient online shopping experience, with fast-loading pages, intuitive navigation, and secure payment options. If your website doesn't deliver on these expectations, you could be losing potential customers to competitors.
                                </span>
                            </div>
                        </article>
                    </section>


                    <figure className='my-12'>
                        <img className='mx-auto' alt='custom ecommerce solutions for small businesses' src={EcommerceImage} title='Custom Ecommerce Solutions for Small Businesses and Startups' />
                    </figure>


                    <section className='mx-16'>
                        <article>
                            <div>
                                <span>At </span>{" "}
                                <span><CompanyName /></span>{" "}
                                <span>, we design and develop e-commerce platforms that are more than just online stores—they're powerful sales tools. Our e-commerce solutions are built to:</span>{" "}

                            </div>
                            <div>
                                <List>
                                    <ListItem>
                                        <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2, color: 'inherit' }}>
                                            <AccessibilityNewIcon />
                                        </Avatar>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Enhance customer experience
                                                </Typography>
                                            }
                                            secondary="Deliver intuitive, user-friendly interfaces that keep customers engaged."
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2, color: 'inherit' }}>
                                            <BuildIcon />
                                        </Avatar>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Streamline your operations
                                                </Typography>
                                            }
                                            secondary="Integrate tools to automate processes, saving time and reducing errors."
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2, color: 'inherit' }}>
                                            <TrendingUpIcon />
                                        </Avatar>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Drive more traffic and conversions
                                                </Typography>
                                            }
                                            secondary="Optimize for SEO and performance to attract visitors and boost sales."
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2, color: 'inherit' }}>
                                            <ExpandMoreIcon />
                                        </Avatar>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    Adapt to your business’s growth
                                                </Typography>
                                            }
                                            secondary="Scale your e-commerce platform seamlessly to meet growing demands."
                                        />
                                    </ListItem>
                                </List>
                            </div>
                        </article>
                    </section>


                    <section className='mx-16'>
                        <article>
                            <div className='text-2xl font-bold py-4'>
                                <h2>The Benefits of Our E-Commerce Solutions</h2>
                            </div>
                            <div>
                                <ECommerceBenefits />
                            </div>
                        </article>

                        <article>
                            <div className='text-2xl font-bold py-4'>
                                <h2>Our E-Commerce Development Process</h2>
                            </div>
                            <div>
                                {ECommerceProcess.map((Process, index) => (
                                    <TransparentAccordion className='my-5'
                                        key={index}
                                        expanded={expanded === `panel${index}`}
                                        onChange={handleChange(`panel${index}`)}
                                    >
                                        <TransparentAccordionSummary
                                            expandIcon={<CustomExpandMoreIcon />}
                                            aria-controls={`panel${index}bh-content`}
                                            id={`panel${index}bh-header`}
                                        >
                                            <div className="font-bold">
                                                <h2>
                                                    <span className='text-5xl text-[#C13E39]'>{Process.id}.</span> <span>{Process.Process}</span>
                                                </h2>
                                            </div>
                                        </TransparentAccordionSummary>
                                        <TransparentAccordionDetails>
                                            <span>{Process.Description}</span>{" "}
                                        </TransparentAccordionDetails>
                                    </TransparentAccordion>
                                ))}
                            </div>
                        </article>
                    </section>
                </div>
            </div>


            <div><WhyChoose {...ChooseEcommerce} /></div>
        </div>
    )
}

export default Ecommerce