// src/Component/PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase'; // Ensure this path is correct

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [user, loading] = useAuthState(auth);

    if (loading) {
        return <div>Loading...</div>; // Show a loading state while checking authentication
    }

    return (
        <Route
            {...rest}
            render={props =>
                user ? (
                    <Component {...props} /> // If authenticated, render the desired component
                ) : (
                    <Redirect
                        to={{
                            pathname: "/Login",
                            state: { from: props.location } // Optionally pass the current location for redirect
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
