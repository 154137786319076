import React from 'react';
import Link from '@mui/material/Link';
import customWebsites from '../Images/customWebsites.svg';
import CompanyName from '../Component/CompanyName';

const Whatdoesawebdesigncompanydo = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div>
            <div className='lg:float-right lg:ml-10 lg:w-1/2'>
                <img
                    className='mx-auto'
                    src={customWebsites}
                    alt='What does a web design company do?'
                    title='What does a web design company do?'
                />
            </div>

            <p className='pb-4'>
                <span>A web design company focuses on building websites that are visually attractive, easy to use, and functional. At </span>{""}
                <span><CompanyName /></span>{""}
                <span>, we specialize in creating custom websites for businesses, organizations, and individuals. We aim to provide affordable and high-quality web design services that meet your needs and help you succeed online.</span>{""}
            </p>

            <div>
                Partnering with the best web design company ensures your website reflects your brand, attracts customers, and helps you achieve your goals. Our professional team works with you to create a website that not only looks great but also performs well on all devices.
            </div>

            <div className='pt-4'>
                <div className='font-bold'><h2>Some essential services include:</h2></div>
            </div>

            <div className='pt-4'>
                <div className='font-bold pb-2'>
                    <Link
                        href='/CustomWebDesign'

                        aria-label='custom web design services'
                        title='Custom web design services'>
                        <div className='font-bold pb-2'>Website Design:</div>
                    </Link>
                </div>

            </div>

            <div>
                A professional web design company develops layouts and designs that align with brand identity, audience preferences, and goals. This process involves creating an engaging user interface (UI) and enhancing the user experience (UX). Choosing the right website design agency ensures an effective and customized design.
            </div>

            <div className='py-4'>
                At <CompanyName />, we understand the importance of a website that works for you. Our expert designers and developers focus on creating websites that not only look good but also drive results.
            </div>

            <div>
                <div className='font-bold pb-2'>Front-end Development:</div>
                <div>
                    Web design companies in the USA transform design concepts into responsive web pages using technologies like HTML, CSS, and JavaScript. Ensuring a seamless experience across devices and screen sizes highlights the importance of combining technical skill with custom design.
                </div>
            </div>

            <div className='pt-4'>
                <div className='font-bold pb-2'>Content Creation:</div>
                <div>
                    Many custom web design companies assist with developing content such as text, images, and multimedia. These services enhance communication with website visitors and contribute to an engaging, professional website experience.
                </div>
            </div>

            <div className='py-4'>
                Our team works closely with you to ensure your content speaks directly to your audience. From captivating visuals to clear messaging, we handle it all.
            </div>

            <div >
                <div className='font-bold pb-2'>Website Maintenance and Updates:</div>
                <div>
                    After launching a website, maintenance services like regular updates, bug fixes, and performance optimizations are essential. Partnering with the best web design company helps ensure these tasks are handled efficiently.
                </div>
            </div>

            <div className='py-4'>
                Trust <CompanyName /> to keep your website running smoothly. We’re here to handle updates and maintenance so you can focus on growing your business.
            </div>

            <div>
                <Link
                    href='/searchEngineOptimization'

                    aria-label='affordable search engine optimization services'
                    title='Affordable search engine optimization services'>
                    <span className='font-bold pb-2'>Search Engine Optimization (SEO):</span>{" "}
                </Link>
                Optimizing website structure and content improves search engine rankings. Professional web design services often integrate SEO strategies, boosting visibility and attracting organic traffic.
            </div>

            <div className='pt-4'>
                <div className='font-bold pb-2'>
                    <Link
                        href='/Ecommerce'

                        aria-label='ecommerce solutions'
                        title='E-commerce solutions'>
                        <div className='font-bold pb-2'>E-commerce Solutions:</div>
                    </Link>
                </div>
                <div>
                    Custom web design companies can create online stores with features like secure payment gateways, product catalogs, and shopping cart functionalities. A well-designed e-commerce platform improves user experience and encourages sales.
                </div>
            </div>

            <div className='pt-4'>
                <div className='font-bold pb-2'>Website Analytics and Reporting:</div>
                <div>
                    Tracking website performance, user behavior, and conversion rates provides valuable insights. Professional web design services include these tools to refine strategies and enhance outcomes.
                </div>
            </div>

            <div className='pt-4'>
                When you choose <CompanyName />, you're not just getting a service—you're gaining a partner dedicated to your success. Let us help you make a lasting impression online in {currentYear}.
            </div>
        </div>
    );
};

export default Whatdoesawebdesigncompanydo;
