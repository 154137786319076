import React from 'react';
import CompanyName from '../Component/CompanyName';
import Cost from '../Images/WebsiteCost.svg';

const Howmuchdoesitcosttobuildawebsite = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <div className='lg:float-right lg:ml-10 mb-10 lg:w-1/2'>
                <img className='mx-auto' src={Cost} alt='website development cost factors' title='How much does it cost to build a website?' />
            </div>

            <div>
                Building a website is a crucial investment for businesses of all sizes. The cost varies based on your unique needs, such as design complexity, functionality, and desired features. At <CompanyName />, we specialize in delivering professional web design services that align with your goals and budget in {currentYear}.
            </div>

            <div className='pt-5'>
                Many factors influence the cost of website development, including the type of website, its size, and any advanced functionalities required. Below are some common categories of websites:
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Simple Websites:</div>
                <div>
                    These are ideal for individuals or small businesses that need a straightforward online presence. With minimal customization, they provide an effective solution for startups seeking affordable web design.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Small to Medium-Sized Business Websites:</div>
                <div>
                    Websites in this category often include custom features such as blogs, contact forms, or integrations. They strike a balance between functionality and cost, offering solutions for growing businesses.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Complex Websites and Web Applications:</div>
                <div>
                    Advanced websites that require unique features, like user accounts or third-party integrations, demand a higher level of customization. Our professional web design team can ensure these solutions are both effective and scalable.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>E-commerce Websites:</div>
                <div>
                    Developing an e-commerce platform involves features such as payment gateways, shopping carts, and inventory management. These websites are designed to deliver seamless online shopping experiences while being aligned with your business requirements.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Website Redesign:</div>
                <div>
                    Refreshing an existing website to meet modern standards or improve usability can provide significant value. This process involves evaluating your current site and implementing strategic changes to align with your brand's vision.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Hosting and Domain Costs:</div>
                <div>
                    Hosting and domain registration are fixed yearly costs essential for maintaining your website. These services ensure your site remains accessible and secure, providing a foundation for your online presence.
                </div>
            </div>

            <div className='pt-5'>
                <div className='font-bold pb-2'>Ongoing Website Costs:</div>
                <div>
                    Beyond the initial development, ongoing maintenance is crucial for keeping your website updated and functional. Partnering with a trusted web design company ensures these services are handled efficiently and affordably.
                </div>
            </div>

            <div className='pt-5'>
                At <CompanyName />, we're committed to helping you achieve an online presence that drives results. Whether you're building a new website or improving an existing one, our team offers expertise and guidance to ensure your project aligns with your business objectives in {currentYear}.
            </div>
        </div>
    );
}

export default Howmuchdoesitcosttobuildawebsite;
