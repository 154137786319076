import React, { useState, useEffect } from 'react';
import './App.css';
import StructuredData from './Component/StructuredData';
import Footer from './Component/Footer';
import { BrowserRouter, Switch, Route } from "react-router-dom"; // Import BrowserRouter
import Navigation from './Component/Navigation';
import Home from './Home';
import Projects from './Portfolio/Projects';
import Testimonial from './Testimonial';
import CustomWebDesign from './CustomWebDesign';
import Redesign from './Redesign';
import SearchEngineOptimization from './SearchEngineOptimization';
import PrivateRoute from "./Component/PrivateRoute";
import CustomWebDevelopment from './CustomWebDevelopment';
import Ecommerce from './Ecommerce';
import UIUXDesign from './UIUXDesign';
import WebsiteSecurity from './WebsiteSecurity';
import FAQ from './FAQ';
import PaymentSuccessful from './PaymentSuccessful';
import TermsofUse from './TermsofUse';
import Sitemap from './Sitemap';
import NotFoundPage from './NotFoundPage';
import { HelmetProvider } from 'react-helmet-async';
import { motion } from 'framer-motion';
import Contact from './Contact';
import Login from './login';
import Main from "./Office/Main";
import QR from './Office/QR';
import Invoicing from './Office/Invoicing';
import SEOFactory from './Office/SEOFactory';
import AllAccounts from './Office/AllAccounts';
import AccountDetails from './Office/AccountDetails';
import Keywords from './Office/Keywords';
import KeywordHub from './Office/KeywordHub';
import MetaAdd from './Office/MetaAdd';
import MetaView from './Office/MetaView';


const pageVariants = {
  initial: { x: "25%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "100%", opacity: 0 },
};

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
  const [isOpen, setIsOpen] = useState(false);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  // Apply dark mode class to body
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('darkMode', isDarkMode);
  }, [isDarkMode]);



  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Ensure the app is mounted before rendering helmet
    setIsReady(true);
  }, []);



  return (
    <div>
      <BrowserRouter>
        <StructuredData />
        <Navigation isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} isOpen={isOpen} setIsOpen={setIsOpen} />
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >

          <HelmetProvider>
            {isReady && (
              <Switch>

                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/Home" component={Home} />
                <Route exact={true} path="/Portfolio/Projects" component={Projects} />
                <Route exact={true} path="/Testimonial" component={Testimonial} />
                <Route exact={true} path="/CustomWebDesign" component={CustomWebDesign} />
                <Route exact={true} path="/Redesign" component={Redesign} />
                <Route exact={true} path="/CustomWebDevelopment" component={CustomWebDevelopment} />
                <Route exact={true} path="/SearchEngineOptimization" component={SearchEngineOptimization} />
                <Route exact={true} path="/Ecommerce" component={Ecommerce} />
                <Route exact={true} path="/UIUXDesign" component={UIUXDesign} />
                <Route exact={true} path="/FAQ" component={FAQ} />
                <Route exact={true} path="/Projects" component={Projects} />

                <Route exact={true} path="/Contact" component={Contact} />
                <Route exact={true} path="/Sitemap" component={Sitemap} />
                <Route exact={true} path="/Login" component={Login} />
                <Route exact={true} path="/PaymentSuccessful" component={PaymentSuccessful} />
                <Route exact={true} path="/WebsiteSecurity" component={WebsiteSecurity} />
                <PrivateRoute exact={true} path="/Office/Main" component={Main} />
                <PrivateRoute exact={true} path="/Office/QR" component={QR} />
                <PrivateRoute exact={true} path="/Office/Invoicing" component={Invoicing} />
                <PrivateRoute exact={true} path="/Office/AllAccounts" component={AllAccounts} />
                <PrivateRoute exact={true} path="/Office/AccountDetails/:id" component={AccountDetails} />
                <PrivateRoute exact={true} path="/Office/Keywords" component={Keywords} />
                <PrivateRoute exact={true} path="/Office/KeywordHub" component={KeywordHub} />
                <PrivateRoute exact={true} path="/Office/MetaAdd" component={MetaAdd} />
                <PrivateRoute exact={true} path="/Office/MetaView" component={MetaView} />
                <PrivateRoute exact={true} path="/Office/SEOFactory" component={SEOFactory} />
                <Route exact={true} path="/TermsofUse" component={TermsofUse} />
                <Route path="*" component={NotFoundPage} />


              </Switch>
            )}
          </HelmetProvider>
        </motion.div>
      </BrowserRouter>

      <div className='mx-16'>
        <Footer />
      </div>

    </div>
  );
}

export default App;