import React from 'react'

function CompanyName() {

    const CompanyName = 'Web Design Expressions'

    return (
        <>
            <span aria-label="Professional Web Design Agency | Affordable Business Website Design Services in the USA" title='USA-Based Web Design Company Specializing in Affordable, Professional Custom Web Design and Development Services'>{CompanyName}</span>


        </>
    )
}

export default CompanyName