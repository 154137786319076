import React from 'react';

const StructuredData = () => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Web Design Expressions",
        "url": "https://www.webdesignexpressions.net",
        "description": "Professional Web Design Agency | Affordable Web Design Company USA",
        // "sameAs": [
        //     "https://www.facebook.com/relydesigns",
        //     "https://www.linkedin.com/company/relydesigns",
        //     "https://twitter.com/relydesigns"
        // ],

        "publisher": {
            "@type": "Organization",
            "name": "Web Design Expressions",
            "logo": {
                "@type": "ImageObject",
                "url": "https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/WebDesignExpressions.svg?alt=media&token=605d7cc9-b4e0-4d50-8793-4f538eb9fd31",
                "width": 200,
                "height": 200
            }
        },
        "contactPoint": {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Web Design Expressions",

            "telephone": "+1-973-419-3293",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-973-419-3293",
                "contactType": "Customer Service",
                "areaServed": "US",
                "availableLanguage": ["English"]
            },

            "address": {
                "@type": "PostalAddress",
                "addressLocality": "New Jersey",
                "addressCountry": "US"
            },
            "image": "https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/WebDesignExpressions.svg?alt=media&token=605d7cc9-b4e0-4d50-8793-4f538eb9fd31"
        },
        "hasPart": [
            {
                "@type": "WebPage",
                "name": "Custom Web Design Services",
                "url": "https://www.webdesignexpressions.net/CustomWebDesign",
                "description": "Custom Web Design Services | Affordable Custom Website Design Agency",
                "inLanguage": "en"
            },
            {
                "@type": "WebPage",
                "name": "Website Redesign Services",
                "url": "https://www.webdesignexpressions.net/Redesign",
                "description": "Affordable Website Redesign Services | Custom Website Redesign",
                "inLanguage": "en"
            }
            ,
            {
                "@type": "WebPage",
                "name": "Search Engine Optimization",
                "url": "https://www.webdesignexpressions.net/searchEngineOptimization",
                "description": "Search Engine optimization",
                "inLanguage": "en"
            },
            {
                "@type": "WebPage",
                "name": "Contact Web Design Expressions",
                "url": "https://www.webdesignexpressions.net/Contact",
                "description": "Contact Web Design Expressions for Custom Web Design Services | Affordable Custom Website Design Agency",
                "inLanguage": "en"
            }
        ],
        "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.webdesignexpressions.net/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Projects",
                    "item": "https://www.webdesignexpressions.net/Projects"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Testimonial",
                    "item": "https://www.webdesignexpressions.net//Testimonial"
                },
                {
                    "@type": "ListItem",
                    "position": 4,
                    "name": "Contact Web Design Expression",
                    "item": "https://www.webdesignexpressions.net/Contact"
                }
            ]
        }
    };

    return (
        <script type="application/ld+json">
            {JSON.stringify(schemaData)}
        </script>
    );
};

export default StructuredData;
