import React from 'react'
import { Helmet } from 'react-helmet-async';
import PageHeader from './Component/PageHeader';
import FAQSection from './FAQ/FAQSection';


export default function FAQ() {

    return (
        <div className='mx-auto'>

            <Helmet prioritizeSeoTags>
                <title>Questions and Answers - How to Choose a Web Design Company</title>
                <meta data-react-helmet="true" name="description" content="How to choose a professional web design agency and create your online presence with the best web design company in the USA." />
                <meta data-react-helmet="true" name="keywords" content="how to choose a web design company, website design agency, professional web design agency, web design company in usa" />
                <meta data-react-helmet="true" property="og:title" content="Questions and Answers - Web Design Company in USA" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/faq" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGHome.jpg?alt=media&token=7b47214c-9a8f-44e4-8b56-10e60bd6e1ed" />
                <meta data-react-helmet="true" property="og:description" content="How to choose a professional web design agency and create your online presence with the best web design company in the USA." />
            </Helmet>
            <PageHeader pageTitle="Questions and Answers" />
            <section className='mx-16'>

                <div className='py-4'>
                    <span>
                        The most effective factor in building and maintaining a sucecessful website starts with the knowledge and planning of what will drive your specific need. Before development, here are some questions to consider for your custom design.
                    </span>{" "}
                </div>

                <FAQSection />


            </section>

        </div>
    )
}
