import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import CompanyName from './CompanyName';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import EnFlag from '../Images/us.svg';
import EsFlag from '../Images/es.svg';
import ZhFlag from '../Images/cn.svg';
import FrFlag from '../Images/fr.svg';
import DeFlag from '../Images/de.svg';
import PtFlag from '../Images/pt.svg';
import ArFlag from '../Images/eg.svg';

const navItems = [
    { name: "Home", path: "/", titleName: " | Professional Web Design Agency" },
    { name: "Projects", path: "/Projects", titleName: " | Previous and Current Custom Web Design Projects" },
    { name: "Testimonial", path: "/Testimonial", titleName: " | Client testimonials of previous Web Design Expressions services" },
    { name: "Contact", path: "/Contact", titleName: " | Web Design Expressions" },
];

const languages = [
    { code: 'en', name: 'English', logo: EnFlag },
    { code: 'es', name: 'Spanish', logo: EsFlag },
    { code: 'zh', name: 'Chinese (Simplified)', logo: ZhFlag },
    { code: 'fr', name: 'French', logo: FrFlag },
    { code: 'de', name: 'German', logo: DeFlag },
    { code: 'pt', name: 'Portuguese', logo: PtFlag },
    { code: 'ar', name: 'Arabic', logo: ArFlag },

];

const Navigation = ({ isDarkMode, toggleDarkMode, isOpen, setIsOpen }) => {
    // Initialize language state with English as default
    const [language, setLanguage] = useState('en');
    const [translationCache, setTranslationCache] = useState({});
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [originalMetadata, setOriginalMetadata] = useState({
        title: '',
        description: '',
        keywords: ''
    });

    // Load saved language and store original metadata on initial mount
    useEffect(() => {
        // Store original metadata
        const title = document.title;
        const description = document.querySelector('meta[name="description"]')?.getAttribute('content') || '';
        const keywords = document.querySelector('meta[name="keywords"]')?.getAttribute('content') || '';

        setOriginalMetadata({
            title,
            description,
            keywords
        });

        // Load saved language
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage && languages.some(lang => lang.code === savedLanguage)) {
            setLanguage(savedLanguage);
        } else {
            localStorage.setItem('selectedLanguage', 'en');
            setLanguage('en');
        }
        setIsInitialLoad(false);
    }, []);

    // Function to translate text using the Google Cloud Translation API
    const translateText = async (text, targetLanguage) => {
        if (!text || targetLanguage === 'en') return text;

        // Check cache first
        const cacheKey = `${text}:${targetLanguage}`;
        if (translationCache[cacheKey]) {
            return translationCache[cacheKey];
        }

        try {
            const response = await fetch(
                `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCuD4i3TtYUAd4AuWkSzkLYRfSqmILqRWI`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        q: text,
                        target: targetLanguage,
                    }),
                }
            );

            const data = await response.json();
            if (data.error) throw new Error(data.error.message);

            const translatedText = data.data.translations[0].translatedText;

            // Cache the translation
            setTranslationCache(prev => ({
                ...prev,
                [cacheKey]: translatedText
            }));

            return translatedText;
        } catch (error) {
            console.error('Translation error:', error);
            return text;
        }
    };

    // Function to translate metadata (title, description, keywords)
    const translateMetadata = async (targetLanguage) => {
        if (targetLanguage === 'en') {
            // Reset to original metadata if language is English
            document.title = originalMetadata.title;

            const descriptionMeta = document.querySelector('meta[name="description"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute('content', originalMetadata.description);
            }

            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (keywordsMeta) {
                keywordsMeta.setAttribute('content', originalMetadata.keywords);
            }
        } else {
            // Translate title
            const translatedTitle = await translateText(originalMetadata.title, targetLanguage);
            document.title = translatedTitle;

            // Translate description
            if (originalMetadata.description) {
                const translatedDescription = await translateText(originalMetadata.description, targetLanguage);
                const descriptionMeta = document.querySelector('meta[name="description"]');
                if (descriptionMeta) {
                    descriptionMeta.setAttribute('content', translatedDescription);
                }
            }

            // Translate keywords
            if (originalMetadata.keywords) {
                const translatedKeywords = await translateText(originalMetadata.keywords, targetLanguage);
                const keywordsMeta = document.querySelector('meta[name="keywords"]');
                if (keywordsMeta) {
                    keywordsMeta.setAttribute('content', translatedKeywords);
                }
            }
        }


    };

    // Function to translate the entire page
    const translatePage = async (targetLanguage) => {
        // First translate metadata
        await translateMetadata(targetLanguage);

        // Define selectors for elements to translate
        const selectors = [
            // Basic HTML elements
            'h1:not([data-no-translate])',
            'h2:not([data-no-translate])',
            'h3:not([data-no-translate])',
            'h4:not([data-no-translate])',
            'h5:not([data-no-translate])',
            'h6:not([data-no-translate])',
            'p:not([data-no-translate])',
            'strong:not([data-no-translate])',
            'li:not([data-no-translate])',
            'span:not([data-no-translate])',
            'a:not([data-no-translate])',
            'button:not([data-no-translate])',
            'label:not([data-no-translate])',


            '.translate-content:not([data-no-translate])',
            'span[data-translate]',
            'span[data-original]',
            '.testimonial-content span',
            'button span[data-translate]',

            // Content-specific classes
            '.offer-description:not([data-no-translate])',
            '.card-content:not([data-no-translate])',
            '.product-description:not([data-no-translate])',
            '.service-details:not([data-no-translate])',

            '[aria-label]:not([data-no-translate])',
            '[title]:not([data-no-translate])',
            '.MuiButton-root:not([data-no-translate]) b',
            '.MuiButton-root:not([data-no-translate]) span',
        ];


        const elements = document.querySelectorAll(selectors.join(', '));

        const processElement = async (element) => {
            // Skip elements that should not be translated
            if (
                element.hasAttribute('data-no-translate') ||
                element.closest('[data-no-translate]') ||
                element.closest('script') ||
                element.closest('style')
            ) {
                return;
            }

            // Special handling for links
            if (element.tagName === 'A') {
                // Skip links that contain only images or icons
                if (element.querySelector('img, svg')) {
                    return;
                }

                // Skip telephone and mailto links
                if (
                    element.getAttribute('href')?.startsWith('tel:') ||
                    element.getAttribute('href')?.startsWith('mailto:')
                ) {
                    return;
                }

                // Store original text content
                let originalText = element.getAttribute('data-original');
                if (!originalText) {
                    // Get only the text content, not the HTML
                    originalText = Array.from(element.childNodes)
                        .filter(node => node.nodeType === Node.TEXT_NODE)
                        .map(node => node.textContent.trim())
                        .join(' ')
                        .trim();

                    if (originalText) {
                        element.setAttribute('data-original', originalText);
                    }
                }

                if (!originalText) return;

                // Create a document fragment to hold non-text nodes
                const fragment = document.createDocumentFragment();
                const nonTextNodes = Array.from(element.childNodes).filter(node =>
                    node.nodeType !== Node.TEXT_NODE
                );
                nonTextNodes.forEach(node => fragment.appendChild(node.cloneNode(true)));

                // Translate and update only the text content
                if (targetLanguage === 'en') {
                    // Clear the element
                    element.textContent = originalText;
                    // Restore non-text nodes
                    element.appendChild(fragment);
                } else {
                    const translatedText = await translateText(originalText, targetLanguage);
                    // Clear the element
                    element.textContent = translatedText;
                    // Restore non-text nodes
                    element.appendChild(fragment);
                }
                return;
            }

            const ariaLabel = element.getAttribute('aria-label');
            if (ariaLabel) {
                const translatedAriaLabel = await translateText(ariaLabel, targetLanguage);
                element.setAttribute('aria-label', translatedAriaLabel);
            }

            // Handle title attribute
            const titleAttr = element.getAttribute('title');
            if (titleAttr) {
                const translatedTitle = await translateText(titleAttr, targetLanguage);
                element.setAttribute('title', translatedTitle);
            }

            // Handle other elements
            // Get or set original text
            let originalText = element.getAttribute('data-original');
            if (!originalText && element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE) {
                originalText = element.innerText.trim();
                if (originalText) {
                    element.setAttribute('data-original', originalText);
                }
            }

            // Skip empty elements
            if (!originalText) return;

            // Translate content
            if (targetLanguage === 'en') {
                element.innerText = originalText;
            } else {
                const translatedText = await translateText(originalText, targetLanguage);
                if (translatedText !== originalText) {
                    element.innerText = translatedText;
                }
            }
        };

        // Process all elements
        for (const element of elements) {
            await processElement(element);
        }
    };

    // Handle language change
    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage);
        localStorage.setItem('selectedLanguage', newLanguage);
        await translatePage(newLanguage);
    };

    // Update HTML lang attribute and translate page when language changes
    useEffect(() => {
        if (!isInitialLoad) {
            document.documentElement.lang = language;
            translatePage(language);
        }
    }, [language, isInitialLoad]);


    return (
        <nav role="navigation" aria-label="Main Navigation">
            <section className="flex justify-between items-center p-4 print:hidden">
                {/* Company Name (Left Side) */}
                <article className="flex-shrink-0 font-bold lg:text-2xl Company-Main-Font">
                    <h2><Link href='/' style={{ color: 'inherit', textDecoration: 'none' }}><CompanyName /></Link></h2>
                </article>

                {/* Navigation Links and Dark Mode Button (Right Side) */}
                <article className="flex items-center space-x-4 ">
                    {/* Navigation Links */}
                    <div className="hidden md:flex space-x-4 Company-Main-Font text-xl">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                href={item.path}
                                title={`${item.name} ${item.titleName}`}
                                className={`${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}`}
                                aria-label={`Go to ${item.name}`}
                                style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer", fontWeight: 'bold' }}
                            >
                                {item.name}
                            </Link>
                        ))}

                        <FormControl variant="standard">
                            <InputLabel id="language-select-label" style={{ color: 'inherit' }}>Language</InputLabel>
                            <Select
                                labelId="language-select-label" // Associates the label with the combobox
                                id="language-select" // Unique ID for the combobox
                                value={language}
                                onChange={handleLanguageChange}
                                label="Language" // Visible label for the dropdown
                                aria-label="Choose a language to Translate" // Fallback for screen readers
                                autoWidth
                                style={{ color: 'inherit' }}
                            >
                                {languages.map((lang) => (
                                    <MenuItem key={lang.code} value={lang.code}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                aria-label="Choose a language to Translate"
                                                src={lang.logo}
                                                alt={`${lang.name} flag`}
                                                style={{ width: "20px", height: "20px", marginRight: '8px' }}
                                                className='w-full h-auto'

                                            />
                                            {lang.name}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    {/* Dark Mode Toggle */}
                    <IconButton
                        onClick={toggleDarkMode}
                        color="inherit"
                        title={isDarkMode ? "Light Mode" : "Dark Mode"}
                        aria-label={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    >
                        {isDarkMode ?
                            <LightModeIcon style={{ color: '#D14009' }} /> :
                            <DarkModeIcon style={{ color: '#F6F1D5' }} />
                        }
                    </IconButton>

                    {/* Mobile Menu Toggle */}
                    <div className="md:hidden">
                        <IconButton
                            onClick={() => setIsOpen(prev => !prev)}
                            style={{ color: isDarkMode ? '#F6F1D5' : '#171a21' }}
                            aria-label="Toggle Menu"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </article>
            </section>

            {/* Mobile Menu */}
            {isOpen && (
                <section className="md:hidden mt-4 Company-Main-Font pl-8">
                    {navItems.map((item) => (
                        <Link
                            key={item.name}
                            href={item.path}
                            title={`${item.name} ${item.titleName}`}
                            className={`block ${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}py-2`}
                            aria-label={`Go to ${item.name}`}
                            style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer", fontWeight: 'bold' }}
                        >
                            {item.name}
                        </Link>
                    ))}

                    <FormControl variant="standard" sx={{ minWidth: 120 }}>

                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            value={language}
                            onChange={handleLanguageChange}
                            label="Language"
                            autoWidth
                            style={{ color: 'inherit' }}
                        >
                            {languages.map((lang) => (
                                <MenuItem key={lang.code} value={lang.code}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={lang.logo}
                                            alt={`${lang.name} flag`}
                                            style={{ width: '20px', marginRight: '8px' }}
                                        />
                                        {lang.name}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </section>


            )}



        </nav>
    );
};

export default Navigation;