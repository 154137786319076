import React from 'react'
import CompanyName from './CompanyName';
import EmailIcon from '@mui/icons-material/Email';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';



const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const WhyChoose = ({

    service,
    companyName,
    introText,
    benefits,
    finalHeader,
    finalText
}) => {
    const currentYear = new Date().getFullYear();

    return (



        <div className='mx-16'>
            <section className='my-5'><Divider /></section>
            <section className='text-2xl font-bold pb-5'>
                <h2>
                    <span>Why Choose</span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>for Your</span>{" "}
                    <span>{service}</span>{" "}
                    <span>?</span>
                </h2>
            </section>

            <article>
                <p><span>{introText}</span>{" "}</p>
            </article>

            <section className='py-8'>
                {benefits.map((benefit, index) => (
                    <article key={index} className="pb-5">
                        <div className="text-lg font-bold pb-2">
                            <span className="text-5xl text-[#C13E39]">{index + 1}. </span>{" "}
                            <span>{benefit.title}</span>{" "}
                        </div>
                        <p>{benefit.description}</p>{" "}
                    </article>
                ))}
                <div className='text-lg font-bold pb-2'>
                    <span>{finalHeader}</span>{" "}
                    <span>in </span>{" "}
                    <span>{currentYear}?</span>{" "}

                </div>
                <div><span>{finalText}</span>{" "}</div>
            </section>

            <div className=''>

                <div className='my-8'>
                    <ThemeProvider theme={theme}>
                        <Button fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size='large' aria-label='Contact Web Design Expressions, the Web design and SEO company' title="Contact Web Design Expressions, the Web design and SEO company" href='/Contact'>
                            <EmailIcon />&nbsp;&nbsp;<b>Contact us</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </div>

        </div >
    )
}

export default WhyChoose