import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import testimonialsData from './Component/Testimonials.json';
import './motion.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const theme = createTheme({
    palette: {
        primary: {
            main: "#23272a", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

function Offer() {
    const [width, setWidth] = useState(0);
    const carousel = useRef();

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }, []);

    const [expandedStates, setExpandedStates] = useState(() =>
        testimonialsData.map(() => false)
    );

    const toggleExpand = (index) => {
        setExpandedStates((prevState) =>
            prevState.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
        );
    };

    return (
        <section className='my-4'>
            <div className="text-center pb-5 text-3xl font-bold"><span>What Our Clients Say</span>{" "}</div>
            <motion.figure ref={carousel} className='carousel' whileTap={{ cursor: "grabbing" }}>
                <motion.article drag='x' dragConstraints={{ right: 0, left: -width }} className='inner-carousel'>
                    {testimonialsData.map((testimonial, index) => {
                        const isExpanded = expandedStates[index];
                        const MAX_LENGTH = 200; // Set a limit for truncated text
                        const isLongText = testimonial.testimonial.length > MAX_LENGTH;

                        return (
                            <motion.section className='flex lg:min-w-[45%] sm:min-w-[95%] m-4' key={testimonial.id || index}>
                                <Card sx={{ color: 'inherit', backgroundColor: 'transparent' }}>

                                    <figure>
                                        <img
                                            src={testimonial.image}
                                            alt={`${testimonial.companyName} - Professional Web Design Agency`}
                                            title={`${testimonial.companyName} - Customer Testimonial | Affordable Web Design Services by Web Design Expressions`}
                                            width="1920"
                                            height="1281"
                                            className="mx-auto"
                                        />
                                    </figure>
                                    <article className='p-8'>
                                        <h2 className='text-xl pb-4 font-bold'>{testimonial.companyName}</h2>
                                        <p>
                                            <span>
                                                {isExpanded
                                                    ? testimonial.testimonial
                                                    : isLongText
                                                        ? `${testimonial.testimonial.slice(0, MAX_LENGTH)}...`
                                                        : testimonial.testimonial}
                                                {isLongText && (

                                                    <button
                                                        className="font-bold mt-2"
                                                        onClick={() => toggleExpand(index)}
                                                    >
                                                        {isExpanded ? (
                                                            <>
                                                                Show Less <ExpandLessIcon className='text-[#C13E39]' />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Show More <ExpandMoreIcon className='text-[#C13E39]' />
                                                            </>
                                                        )}
                                                    </button>

                                                )}
                                            </span>
                                        </p>

                                        <div className='text-right pt-5'>
                                            <ThemeProvider theme={theme}>
                                                <Button
                                                    fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit', fontWeight: 'bold' }} size='large'
                                                    className="mx-auto"
                                                    title={`Visit ${testimonial.companyName}`}
                                                    href={testimonial.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span>{testimonial.companyName}</span>&nbsp;&nbsp; <ExitToAppIcon />
                                                </Button>
                                            </ThemeProvider>
                                        </div>
                                    </article>

                                </Card>
                            </motion.section>
                        );
                    })}
                </motion.article>
            </motion.figure>
        </section>
    );
}

export default Offer;
