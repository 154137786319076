import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PageHeader from './Component/PageHeader';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const PaymentSuccessful = () => {
    return (
        <div>
            <div><PageHeader pageTitle="Payment Successful!" /></div>

            <section className='mx-16'>
                <article className='lg:flex lg:gap-5'>
                    <figure className='lg:w-3/12'>
                        <div className='text-6xl font-bold Company-Main-Font pt-8 text-center'><span>Thank You</span><sup><FavoriteIcon className='text-[#C13E39]' /></sup></div>
                    </figure>
                    <section className='lg:w-9/12'>
                        <div className='pt-8'>
                            <p>
                                Thank you for your payment! Your transaction has been completed successfully.
                            </p>
                            <p className='py-4'>
                                A confirmation email has been sent to your registered email address. If you have any
                                questions or need further assistance, feel free to contact our support team.
                            </p>
                        </div>

                        <div className='lg:w-1/2 mx-auto my-10'>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Button className='' fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size='large' aria-label='Contact Web Design Expressions, the Web design and SEO company' title="Contact Web Design Expressions, the Web design and SEO company" href='/Contact'>
                                        <EmailIcon />&nbsp;&nbsp;<b>Contact us</b>
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </section>
                </article>
            </section>


        </div>
    )
}

export default PaymentSuccessful