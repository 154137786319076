import React from 'react';
import PageHeader from '../Component/PageHeader';
import {
    Card,
    CardContent,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Switch,
    FormControlLabel
} from '@mui/material';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { db } from '../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';

const domains = [
    { value: "Select an Account", label: "Select an Account" },
    { value: "webdesignexpressions.net", label: "webdesignexpressions.net" },
    { value: "bostonairportlimos.com", label: "bostonairportlimos.com" },
    { value: "driverlicenseprep.com", label: "driverlicenseprep.com" },
    { value: "chartergoinfissioncabo.com", label: "chartergoinfissioncabo.com" }
];


// Fields we want to track and display
const metadataFields = {
    title: { label: "Title", show: false, maxLength: 60 },
    description: { label: "Description", show: false, maxLength: 150 },
    ogtitle: { label: "OG Title", show: false, maxLength: 60 },
    ogdescription: { label: "OG Description", show: false, maxLength: 200 },
    image: { label: "Image", show: false },
    keywords: { label: "Keywords", show: false },
    ogtype: { label: "Type", show: false },
};

// Define the custom page order
const pageOrder = {
    "Home": 1,
    "Projects": 2,
    "Testimonial": 3,
    "Contact": 4,
    "CustomWebDesign": 5,
    "Redesign": 6,
    "SearchEngineOptimization": 7,
    "CustomWebDevelopment": 8,
    "Ecommerce": 9,
    "UIUXDesign": 10,
    "WebsiteSecurity": 11,
    "FAQ": 12,
    "TermsofUse": 13,
    "Sitemap": 14,

};

const MetaView = () => {
    const [selectedDomain, setSelectedDomain] = React.useState('');
    const [allPagesMetadata, setAllPagesMetadata] = React.useState({});
    const [status, setStatus] = React.useState({ type: '', message: '' });
    const [duplicates, setDuplicates] = React.useState({});
    const [selectedFields, setSelectedFields] = React.useState(metadataFields);

    const renderCharacterCount = (text = '', maxLength) => {
        const count = text.length;
        const color = count > maxLength ? '#ff4444' : '#666666';
        return (
            <Typography
                variant="caption"
                sx={{
                    color,
                    display: 'block',
                    mt: 1,
                    fontWeight: count > maxLength ? 'bold' : 'normal'
                }}
            >
                {count}/{maxLength} characters
            </Typography>
        );
    };

    const renderFieldContent = (field, value, maxLength) => {
        if (field === 'image' && value) {
            return (
                <img
                    src={value}
                    alt="metadata"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                />
            );
        }

        return (
            <div>
                <div style={{
                    color: maxLength && value?.length > maxLength ? '#ff4444' : 'inherit',
                    wordBreak: 'break-word'
                }}>
                    {value}
                </div>
                {maxLength && renderCharacterCount(value, maxLength)}
            </div>
        );
    };

    const checkDuplicates = (allPages) => {
        const duplicateMap = {};
        const valueMap = {};

        Object.entries(allPages).forEach(([pagePath, pageData]) => {
            Object.entries(pageData).forEach(([field, value]) => {
                if (value && typeof value === 'string' && value.trim() !== '') {
                    const key = `${field}:${value}`;
                    if (!valueMap[key]) {
                        valueMap[key] = [];
                    }
                    valueMap[key].push(pagePath);
                }
            });
        });

        Object.entries(valueMap).forEach(([key, pages]) => {
            if (pages.length > 1) {
                const [field] = key.split(':');
                if (!duplicateMap[field]) {
                    duplicateMap[field] = {};
                }
                const value = key.substring(field.length + 1);
                duplicateMap[field][value] = pages;
            }
        });

        return duplicateMap;
    };

    const sortPages = (pages) => {
        return Object.entries(pages).sort((a, b) => {
            const orderA = pageOrder[a[0]] || Infinity;
            const orderB = pageOrder[b[0]] || Infinity;
            if (orderA === orderB) {
                return a[0].localeCompare(b[0]); // Alphabetical order for pages with no defined order
            }
            return orderA - orderB;
        });
    };

    React.useEffect(() => {
        const fetchAllPagesMetadata = async () => {
            if (selectedDomain && selectedDomain !== 'Select an Account') {
                try {
                    const docRef = doc(db, 'Pages', selectedDomain);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setAllPagesMetadata(data.pages || {});
                        const duplicatesFound = checkDuplicates(data.pages);
                        setDuplicates(duplicatesFound);
                    } else {
                        setAllPagesMetadata({});
                        setDuplicates({});
                    }
                } catch (error) {
                    setStatus({ type: 'error', message: `Error fetching pages: ${error.message}` });
                    setAllPagesMetadata({});
                    setDuplicates({});
                }
            }
        };

        fetchAllPagesMetadata();
    }, [selectedDomain]);

    const handleDomainChange = (event) => {
        setSelectedDomain(event.target.value);
        setAllPagesMetadata({});
    };

    const handleFieldToggle = (field) => {
        setSelectedFields(prev => ({
            ...prev,
            [field]: { ...prev[field], show: !prev[field].show }
        }));
    };

    const renderComparativeView = () => (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Page</strong></TableCell>
                        {Object.entries(selectedFields).map(([field, { label, show }]) => (
                            show && (
                                <TableCell key={field}>
                                    <span>{label}</span>
                                    {metadataFields[field].maxLength && (
                                        <Typography variant="caption" sx={{ display: 'block', color: '#666666' }}>
                                            Max: {metadataFields[field].maxLength} chars
                                        </Typography>
                                    )}
                                </TableCell>
                            )
                        ))}
                        <TableCell><strong className='print:hidden'>Duplicate Status</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortPages(allPagesMetadata).map(([page, metadata]) => (
                        <TableRow key={page}>
                            <TableCell><span>{page}</span></TableCell>
                            {Object.entries(selectedFields).map(([field, { show }]) => (
                                show && (
                                    <TableCell key={field}>

                                        {renderFieldContent(
                                            field,
                                            metadata[field],
                                            metadataFields[field].maxLength
                                        )}

                                    </TableCell>
                                )
                            ))}
                            <TableCell>
                                {Object.entries(selectedFields).map(([field, { show }]) => (
                                    show && duplicates[field]?.[metadata[field]] && (
                                        <Typography key={field} color="error">
                                            {field}: Duplicate in {duplicates[field][metadata[field]]
                                                .filter(p => p !== page).join(', ')}
                                        </Typography>
                                    )
                                ))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <div>
            <div><PageHeader pageTitle="Meta Information" /></div>
            <div className="mx-16">
                <Card sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: 0,
                    backgroundColor: 'transparent',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                }}>
                    <CardContent>
                        <Stack spacing={3}>
                            <div className='print:hidden'>
                                <FormControl fullWidth>
                                    <InputLabel id="domain-select-label">Select Domain</InputLabel>
                                    <Select
                                        labelId="domain-select-label"
                                        value={selectedDomain}
                                        label="Select Domain"
                                        onChange={handleDomainChange}
                                    >
                                        {domains.map((domain) => (
                                            <MenuItem key={domain.value} value={domain.value}>
                                                {domain.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {Object.entries(selectedFields).map(([field, { label, show }]) => (
                                    <FormControlLabel
                                        key={field}
                                        control={
                                            <Switch
                                                checked={show}
                                                onChange={() => handleFieldToggle(field)}
                                            />
                                        }
                                        label={label}
                                    />
                                ))}
                            </Box>

                            {Object.keys(allPagesMetadata).length > 0 && renderComparativeView()}

                            {status.message && (
                                <Alert severity={status.type === 'error' ? 'error' : 'success'}>
                                    {status.message}
                                </Alert>
                            )}
                        </Stack>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default MetaView;